<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="horse_robe">{{ $t('horse.robe') }}</label>	
        <div class="col-8">
            <b-input-group >
                <select
                    id="horse_robe"
                    class="custom-select"
                    ref="select_robe"
                    :disabled="!editable"
                    :placeholder="$t('horse.robe_cheval')"
                    v-on:change="checkSelect"
                    v-model="selected_robe"
                    @change="$emit('change', $event.target.value)"
                >
                    <option
                        v-for="horse_robe in horse_robes"
                        :key="horse_robe.robe_id"
                        :value="horse_robe.robe_code"
                        >{{ horse_robe.robe_label }}
                    </option>
                </select>
            </b-input-group>
        </div>
    </div>

</template>

<script type="text/javascript">
    import Config from "@/mixins/Config.js";
    import HorseMixin from "@/mixins/Horse.js";

    export default {
        mixins: [Config, HorseMixin],
        props: [
            'horse_id',
            'horse_robe_code',
            'editable'
        ],
        data () {
            return {
                horse_robes: [],
                edit_field_robe: false,
                selected_robe: "",
                local_horse_robe_code: ""
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_robes = await this.getRobes()
                this.local_horse_robe_code = this.horse_robe_code
                this.selected_robe = this.local_horse_robe_code
            },
            checkSelect() {
                if(this.selected_robe != this.local_horse_robe_code ) {
                    this.edit_field_robe = true
                    this.local_horse_robe_code = this.selected_robe
                }
            },
            openSelect() {
                this.$refs.select_robe.click()
            },
            saveSelect() {
                this.edit_field_robe = false
                this.updateHorseRobe(this.horse_id, this.selected_robe)
            }
        },
        watch:{
            horse_robe_code(){
                this.selected_robe = this.horse_robe_code
                this.checkSelect()
            }
        }
    }
</script>
